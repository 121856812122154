import { render, staticRenderFns } from "./NewYearReset.vue?vue&type=template&id=61927d84&scoped=true&"
import script from "./NewYearReset.vue?vue&type=script&lang=js&"
export * from "./NewYearReset.vue?vue&type=script&lang=js&"
import style0 from "./NewYearReset.vue?vue&type=style&index=0&id=61927d84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61927d84",
  null
  
)

export default component.exports