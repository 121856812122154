import Vue from "vue";
import VueRouter from "vue-router";
/*
import Browser from "../views/Browser.vue";
import RideStatus from "../views/RideStatus.vue";
import RideLeaders from "../views/RideLeaders.vue";
*/

import Root from "../components/Root.vue";
// import rideReview from "../views/RideReview.vue";
import newYearReset from "../views/NewYearReset.vue";
import msgEditor from "../views/MsgEditor.vue";
//import About from "../views/About.vue";
import Login from "../views/Login.vue";
import moment from "moment/moment";
function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Root",
    props: false,
    component: Root,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/Login",
    name: "Login",
    props: true,
    component: lazyLoad("Login"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/RideStatus",
    name: "RideStatus",
    props: false,
    component: lazyLoad("RideStatus"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    key: "/NewYearReset",
    path: "/NewYearReset/:resetDate/",
    name: "newYearReset",
    props: true,
    component: newYearReset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/RideReview/:rideLeader/:rideStatus",
    name: "rideReview",
    props: true,
    component: lazyLoad("RideReview"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/RideLeaders",
    name: "RideLeaders",
    props: false,
    component: lazyLoad("RideLeaders"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Browser",
    props: false,
    name: "Browser",
    component: lazyLoad("Browser"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/List",
    name: "List",
    props: false,
    component: lazyLoad("List"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/About",
    name: "About",
    props: false,
    component: lazyLoad("About"),
    //component: lazyLoad("About"),
    meta: {
      requiresAuth: true,
    },
  },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //    component: lazyLoad("() =>
  //      import(/* webpackChunkName: "about" */ "../views///List.vue"),

  {
    path: "/msgEditor/:filename/:title/",
    key: "/msgEditor/:filename/:title/",
    name: "msgEditor",
    props: true,
    component: msgEditor,
    meta: {
      requiresAuth: true,
    },
  },
];
const router = new VueRouter({
  mode: "history",
  base: "/vue/",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth === undefined) {
    next("/Login");
    return;
  }
  if (to.meta.requiresAuth === true) {
    if (sessionStorage.getItem("requiresAuth") === null) {
      next("/Login");
      return;
    }
    if (sessionStorage.getItem("requiresAuth") === true) {
      next("/Login");
      return;
    }
  }
  next();
});

export default router;
